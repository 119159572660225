import React, { Component } from 'react';
import _ from 'underscore';
import moment from 'moment';
import $ from 'jquery';
import { Icon, Modal, Dropdown, Checkbox } from 'semantic-ui-react';

import i18n from '../../../utils/i18n';
import defaults from '../../../utils/defaults';

class HomeworkFormular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: null,
      type: null,
      selectedStudents: {},
      selectedCategories: [],
      questionCount: 16,
      examPercentage: 75,
      enddate: moment().add(1, 'day').format("YYYY-MM-DD"), // in 1 day
    };

    this.defaultCategories = defaults.defaultCategories.concat("NVFR");
    this.selectableGroups = _.map(this.props.groups, function(group) { 
      return {
        key: group,
        text: group,
        value: group,
      }
    });

    this.noGroupsAvailable = this.noGroupsAvailable.bind(this);
    this.getListOfAvailableCategories = this.getListOfAvailableCategories.bind(this);
    this.getListOfStudents = this.getListOfStudents.bind(this);
    this.selectType = this.selectType.bind(this);
    this.update = this.update.bind(this);
  }

  update(options, data) {
    options = options || {};
    let name = options.target.name || (data && data.name);
    let value = options.target.value || (data && data.value);
    let { selectedCategories, selectedStudents } = this.state;
    let obj = {};

    // when selecting students
    if (data && data.selector && data.selector === 'students') {
      name = 'selectedStudents';
      const exists = selectedStudents[data.id];
      if (!exists) {
        selectedStudents[data.id] = data.label;
      } else {
        // remove it
        delete selectedStudents[data.id];
      }
      value = selectedStudents;
    }

    // selecting questionCount and examPercentage
    if (options.name === "questionCount" || options.name === "examPercentage") {
      value = parseInt(value);
    }

    // when selecting categories
    if (!name && !value) {
      name = data && data.id;
      if (data.checked) {
        const exists = selectedCategories.includes(name);
        // IF exists remove
        if (!exists) {
          selectedCategories.push(name);
        } else {
          const index = selectedCategories.indexOf(name);
          if (index > -1) selectedCategories.splice(index, 1);
        }
      } else {
        selectedCategories = selectedCategories.filter(item => item !== name);
      }
      obj = { selectedCategories };
    } else {
      obj[name] = value;
    }
    
    this.setState(obj, () => {
      const {type, group, selectedCategories, enddate, selectedStudents, questionCount, examPercentage} = this.state;
      if (type === 'students') {
        this.props.updateContent({
          data: {"homework": { selectedStudents, selectedCategories, enddate, questionCount, examPercentage }}, 
          id: 'homework'
        });
      } else {
        this.props.updateContent({
          data: {"homework": { group, selectedCategories, enddate, questionCount, examPercentage }}, 
          id: 'homework'
        });
      }
    });
  }

  noGroupsAvailable() {
    return !this.props.groups || _.isEmpty(this.props.groups);
  }

  getTranslation(key) {
    return i18n(key) || key;
  }

  getListOfAvailableCategories() {
    const that = this;
    const categories = this.defaultCategories.concat(this.props.categories);
    return _.map(categories, function(category) {
      return <Checkbox key={category} onChange={(options, data) => that.update(options, data)} id={category} className="mm-form-hue-item" label={that.getTranslation(category)} />;
    });
  }

  getListOfStudents() {
    const that = this;
    const students = _.filter(this.props.students, function (student) { return student.verified; });
    return _.map(students, function(student) {
      const description = student.description;
      let name = student.name || student.firstname + " " + student.lastname;
      if (description) name += ` (${description})`;
      return <Checkbox key={student.email} onChange={(options, data) => that.update(options, data)} selector='students' id={student.email} label={name} className="mm-form-hue-item flex-50" />;
    });
  }

  selectType(type, element) {
    $('.choose-exam').removeClass('is-selected');
    $('.' + element).addClass('is-selected');
    this.setState({ type })
  }

  render() {
    if (this.noGroupsAvailable()) {
      // TODO nicer Alert
      alert(i18n('first_add_a_group'));
      this.props.onClose();
      return "";
    }
    return <Modal.Content scrolling className="mm-content-container">
      <Modal.Header>{i18n("create_new_homework")}</Modal.Header>
      <div className='mm-form-container create-homework'>

      <div className='headline'><span className='headline-description'>{i18n("which_kind_of_homework_should_be_done")}</span></div>

      <div className='exams-box flex-wrap'>
        <div className='choose-exam exam2' onClick={() => this.selectType('class', 'exam2')}>
          <img src="/img/ato/students.png" alt={i18n("create_class_homework")} />
          <span className="box-headline">{i18n("create_class_homework")}</span>
        </div>
        <div className='choose-exam exam3' onClick={() => this.selectType('students', 'exam3')}>
          <img src="/img/ato/student.png" alt={i18n("create_homework_for_certain_students")} />
          <span className="box-headline">{i18n("create_homework_for_certain_students")}</span>
        </div>
      </div>
    
      { // Show next when group is choosen
          this.state.type === 'class' ?
          <><div className='headline'>{i18n("choose_class")} <span className='headline-description'>{i18n("choose_class_hint")}</span></div><div className='mm-form-item form-item-padding-bottom'>
              <Icon name="list" title={i18n("choose_class_hint")} />
              <Dropdown
                placeholder={i18n("class")}
                fluid
                selection
                options={this.selectableGroups}
                name="group"
                onChange={(options, data) => this.update(options, data)} />
            </div></>
        : null
      }

      { // Show next when group is choosen
          this.state.type === 'students' ?
          <><div className='headline'>{i18n("choose_student")} <span className='headline-description'>{i18n("choose_student_hint")}</span></div><div className='mm-form-item flex-wrap'>
              { this.getListOfStudents() }
            </div></>
        : null
      }

        { // Show next when group is choosen
          this.state.group || !_.isEmpty(this.state.selectedStudents) ?
            <div>
              <div className='headline'>{i18n("choose_categories")} <span className='headline-description'>{i18n("which_categories_should_be_done_as_homework")}</span></div>
              <div className='mm-form-item-list'>
                { this.getListOfAvailableCategories() }
              </div>
              <div>
                <div className='headline'>{i18n("full_count_for_homework")} <span className='headline-description'>{i18n("full_count_for_exam_description")}</span></div>
                <div className='mm-form-item-list'>
                  <span>{i18n("number")}: <b>{this.state.questionCount}</b></span>
                  <input onChange={this.update} min="1" max="50" name="questionCount" step="1" type="range" value={this.state.questionCount}></input>
      
                </div>
              </div>
              <div>
                <div className='headline'>{i18n("minimum_exam_percentage")} <span className='headline-description'>{i18n("full_minimum_exam_percentage")}</span></div>
                <div className='mm-form-item-list'>
                  <span>{i18n("percentage")}: <b>{this.state.examPercentage}%</b></span>
                  <input onChange={this.update} min="1" max="100" name="examPercentage" step="1" type="range" value={this.state.examPercentage}></input>
      
                </div>
              </div>
              <div>
                <div className='headline'>{i18n("choose_enddate")} <span className='headline-description'>{i18n("by_when_must_the_homework_be_done")}</span></div>
                <div className='mm-form-item-list'>
                <input className='input-date' type="date" name="enddate" onChange={this.update} value={this.state.enddate} min={moment().format("YYYY-MM-DD")} max={moment().add(2, 'month').format("YYYY-MM-DD")} />
                </div>
              </div>
            </div>
          : null
        }
        
      </div>  
    </Modal.Content>
  }
}

export default HomeworkFormular;
