import React, { Component } from 'react';
import { Table, Checkbox } from 'semantic-ui-react'

import _ from 'underscore';
import { Icon } from 'semantic-ui-react'
import i18n from "../utils/i18n";
import Loading from './Loading';

import API from '../utils/resources/ato.js';
import helper from '../utils/ato/helper.js'

import PreviewBoxes from '../components/manager/PreviewBoxes';
import TableList from '../components/manager/TableList';
import SearchContainer from '../components/manager/SearchContainer';
import CreateItemModal from '../components/manager/CreateItemModal';

import StudentsFormular from '../components/manager/formular/StudentsFormular';
import StudentFormular from '../components/manager/formular/StudentFormular';
import GroupsFormular from '../components/manager/formular/GroupsFormular';
import CategoriesFormular from '../components/manager/formular/CategoriesFormular';
import QuestionsFormular from '../components/manager/formular/QuestionsFormular';
import HomeworkFormular from '../components/manager/formular/HomeworkFormular';
import AnswerFormular from '../components/manager/formular/AnswerFormular';
import ScenarioFormular from '../components/manager/formular/ScenarioFormular';
import HomeworkStatisticFormular from '../components/manager/formular/HomeworkStatisticFormular';
import ExamStatisticFormular from '../components/manager/formular/ExamStatisticFormular';
import ArchiveFormular from '../components/manager/formular/ArchiveFormular';
import ExamFormular from '../components/manager/formular/ExamFormular';
import DocumentsFormular from '../components/manager/formular/DocumentsFormular';
import moment from 'moment';

class ManageFlightschool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openModal: false,
      formular: null,
      formularData: null,
      isActiveSearch: false,
      searchTerm: "",
      categories: [],
      groups: [],
      questions: {},
      students: {},
      homework: {},
      exam: {},
      answers: {},
      scenarios: {},
      documents: {},
      formularOptions: null,
      formularType: null,
      formularActionLabel: null,
    };

    this.isLoading = this.isLoading.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setLoaded = this.setLoaded.bind(this);
    this.createNewTask = this.createNewTask.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSaveData = this.onSaveData.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.updateAvailableCategories = this.updateAvailableCategories.bind(this);
    this.updateAvailableGroups = this.updateAvailableGroups.bind(this);
    this.updateAvailableQuestions = this.updateAvailableQuestions.bind(this);
    this.updateAvailableStudents = this.updateAvailableStudents.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.deleteAnswer = this.deleteAnswer.bind(this);
    this.updateTaskFormular = this.updateTaskFormular.bind(this);
    this.updateQuestionState = this.updateQuestionState.bind(this);
    this.getUnverifiedStudentItem = this.getUnverifiedStudentItem.bind(this);
    this.saveData = this.saveData.bind(this);
    this.getStudentDetails = this.getStudentDetails.bind(this);
    this.shouldHideSaveAction = this.shouldHideSaveAction.bind(this);
    this.closeSearchContainer = this.closeSearchContainer.bind(this);
    this.updateStudentState = this.updateStudentState.bind(this);
    this.toggleCategoryVisibility = this.toggleCategoryVisibility.bind(this);
    this.toggleStudentsVisibility = this.toggleStudentsVisibility.bind(this);
    this.openHomeworkStatistic = this.openHomeworkStatistic.bind(this);
    this.openExamStatistic = this.openExamStatistic.bind(this);
    this.openArchive = this.openArchive.bind(this);
    this.prepareCategories = this.prepareCategories.bind(this);
    this.onInnerManagerAction = this.onInnerManagerAction.bind(this);

    /*
    // Available Methods to get Data
    // this.updateAvailableCategories();
    // this.updateAvailableQuestions();
    // this.updateAvailableStudents();
    // Updating ALL Data
    this.fetchData();
    */
  }

  isLoading() { return this.state.loading }
  setLoading() { this.setState({loading: true}) }
  setLoaded() { this.setState({loading: false}) }
  getFirstName() { return this.props.userinfo.idToken.claims.firstname }
  getATOName() { return this.props.userinfo.idToken.claims.ato_name }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const that = this;
    this.setLoading();
    const data = API.getATOData();
    data.then(function(response) {
      const { categories, questions, students, groups, homework, exam, answers, scenarios, documents } = response;
      that.setState({
        categories,
        questions,
        students,
        groups,
        homework,
        exam,
        answers,
        scenarios,
        documents,
      });
      that.setLoaded();
    });
  }
  
  updateAvailableStudents() {
    const that = this;
    API.updateAvailableStudents(function(students) {
      that.setState({ students });
    });
  }

  updateQuestionState(event, data, entry) {
    const that = this;
    const { checked } = data;
    let hidden = true;
    if (checked === false) {
      hidden = false;
    }
    entry.hidden = hidden;
    API.updateQuestion({"question": entry}).then((questions) => {
      // that.setState({ questions });
      that.fetchData();
    });
  }

  deleteStudent(id) {
    const that = this;
    if (window.confirm(i18n("confirm_deleting_student"))) {
      API.deleteStudent({ id }).then((students) => {
        // that.setState({ students });
        that.fetchData();
      });
    }
  }
  
  deleteCategory(category) {
    const that = this;
    if (window.confirm(i18n("confirm_deleting_category"))) {
      API.deleteCategory(category).then((categories) => {
        // that.setState({ categories });
        that.fetchData();
      });
    }
  }
  
  deleteGroup(group) {
    const that = this;
    if (window.confirm(i18n("confirm_deleting_group"))) {
      API.deleteGroup(group).then((groups) => {
        // that.setState({ categories });
        that.fetchData();
      });
    }
  }

  deleteScenario(id) {
    const that = this;
    if (window.confirm(i18n("confirm_deleting_scenario"))) {
      API.deleteScenario(id).then((scenarios) => {
        // that.setState({ categories });
        that.fetchData();
      });
    }
  }

  deleteDocument(id) {
    const that = this;
    if (window.confirm(i18n("confirm_deleting_document"))) {
      API.deleteDocument(id).then((scenarios) => {
        // that.setState({ documents });
        that.fetchData();
      });
    }
  }
  
  deleteQuestion(question) {
    const that = this;
    if (window.confirm(i18n("confirm_deleting_question"))) {
      API.deleteQuestion(question).then((questions) => {
        // that.setState({ questions });
        that.fetchData();
      });
    }
  }
  
  deleteHomework(homework) {
    const that = this;
    if (window.confirm(i18n("confirm_deleting_homework"))) {
      API.deleteHomework(homework).then((homework) => {
        // that.setState({ homework });
        that.fetchData();
      });
    }
  }

  deleteExam(exam) {
    const that = this;
    if (window.confirm(i18n("confirm_deleting_exam"))) {
      API.deleteExam(exam).then((exam) => {
        // that.setState({ exam });
        that.fetchData();
      });
    }
  }

  deleteAnswer(question) {
    const that = this;
    if (window.confirm(i18n("confirm_deleting_answer"))) {
      API.deleteAnswer(question).then((questions) => {
        // that.setState({ questions });
        that.fetchData();
      });
    }
  }

  openHomeworkStatistic(homeworkID) {
    let homework = this.state.homework[homeworkID];
    if (!homework) {
      homework = _.find(this.state.homework, function (obj) {
        return obj.id === homeworkID;
      });
      homework = homework || this.state.homework[0];
    }
    this.setState({ formularOptions: { homework }});
    this.createNewTask("homeworkStatistic");
  }

  openExamStatistic(examID) {
    let exam = this.state.exam[examID];
    if (!exam) {
      exam = _.find(this.state.exam, function (obj) {
        return obj.id === examID;
      });
      exam = exam || this.state.exam[0];
    }
    this.setState({ formularOptions: { exam }});
    this.createNewTask("examStatistic");
  }

  isVerifiedStudent(entry) {
    return entry && entry.verified === true;
  }

  getUnverifiedStudentItem(entry) {
    if (!this.isVerifiedStudent()) {
      return <small className='color-red'><b> ({i18n("not_verified")})</b></small>;
    }
    return "";
  }

  getStudentDetails(student) {
    if (!this.isVerifiedStudent(student)) return;
    this.setState({ formularOptions: { student }})
    this.createNewTask("student");
  }


  updateStudentState(event, data, entry) {
    const that = this;
    const { checked } = data;
    entry.isAdmin = checked;
    API.updateStudent({"student": entry}).then((students) => {
      that.fetchData();
    });
  }

  toggleStudentsVisibility(category) {
    this.setState((prevState) => ({
      studentCategory: prevState.studentCategory === category ? null : category,
    }));
  }

  renderAvailableStudents() {
    const that = this;
    if (_.isEmpty(this.state.students)) return null;

    const groupedStudents = _.groupBy(this.state.students, 'group');

    return Object.keys(groupedStudents).map((category) => {
      const studentsInCategory = groupedStudents[category];
      const stundentCount = studentsInCategory && studentsInCategory.length;
      const thisCategoryIsOpen = this.state.studentCategory === category;
      const accordionIcon = thisCategoryIsOpen ? "triangle up" : "triangle down";

      return (
        <React.Fragment key={category}>
          <Table.Row>
            <Table.Cell colSpan="5" className="collapsible-item" onClick={() => this.toggleStudentsVisibility(category)}>
              {i18n(category) || category} <span>({stundentCount})</span> <Icon name={accordionIcon} />
            </Table.Cell>
          </Table.Row>
          {this.state.studentCategory === category && studentsInCategory.map((entry, index) => {
            return (
              <Table.Row key={index}>
                <Table.Cell>
                <Checkbox className='no-spaces' toggle checked={entry.isAdmin} onChange={(event, data) => that.updateStudentState(event, data, entry)} />
              </Table.Cell>
              <Table.Cell className={`max-width ${that.isVerifiedStudent(entry) && "cursor-pointer"}`} title={entry.name} onClick={() => that.getStudentDetails(entry)}>{that.isVerifiedStudent(entry) && <Icon name="external" />} {entry.name}</Table.Cell>
              <Table.Cell className={`max-width ${that.isVerifiedStudent(entry) && "cursor-pointer"}`} title={entry.email} onClick={() => that.getStudentDetails(entry)}>
                {entry.email}
                { !that.isVerifiedStudent(entry) ? that.getUnverifiedStudentItem(entry) : '' }
              </Table.Cell>
              <Table.Cell className="max-width" title={entry.description}>{entry.description}</Table.Cell>
              <Table.Cell className="dyn-width" title={entry.group}>{entry.group}</Table.Cell>
              <Table.Cell className="dyn-width action-content action-content-question">
                <div className="single-action" onClick={() => that.deleteStudent(entry.id)}>{i18n('delete')}</div>
                <div className="single-action" onClick={() => that.updateTaskFormular("student", entry)}>{i18n('edit')}</div>
                </Table.Cell>
            </Table.Row>
            );
          })}
        </React.Fragment>
      );
    });
  }

  updateAvailableCategories() {
    const that = this;
    API.getCategories(function(categories) {
      that.setState({ categories });
    });
  }

  renderAvailableCategories() {
    const that = this;
    if (_.isEmpty(this.state.categories)) return null;
    return _.map(this.state.categories, function(entry, index) {
      const translation = i18n(entry) || entry;
      return <Table.Row key={index}>
              <Table.Cell className="max-width">{translation}</Table.Cell>
              <Table.Cell className="dyn-width action-content" onClick={() => that.deleteCategory(entry)}>{i18n('delete')}</Table.Cell>
            </Table.Row>
    });
  }

  updateAvailableHomework() {
    const that = this;
    API.getHomework(function(homework) {
      that.setState({ homework });
    });
  }

  getCategorieTranslations(categories) {
    return _.map(categories, function(category) {
      return i18n(category) || category;
    }).join(", ");
  }

  getReadableDate(entry) {
    const hasNoEndDate = entry.noEndDate;
    if (hasNoEndDate) return i18n("no_enddate");
  
    const date = entry.enddate;
    return moment(date).format("DD. MMM YYYY");
  }

  getFullReadableDate(entry) {
    const hasNoEndDate = entry.noEndDate;
    if (hasNoEndDate) return i18n("no_enddate");

    const date = entry.enddate;
    const day = moment(date).format("DD. MMM YYYY");
    const hour = moment(date).format("HH:mm");
    return day + " (" + hour + " " + i18n("oClock") + ")";
  }

  getWorkingGroup(data) {
    return data.group || _.map(data.selectedStudents, function (student) { return student }).join(', ') || '';
  }

  renderAvailableHomework() {
    const that = this;
    if (_.isEmpty(this.state.homework)) return null;
    return _.map(this.state.homework, function(entry, index) {
      let isOver = false;
      const givenDate = moment(entry.enddate);
      const currentDate = moment();
      if (givenDate.isBefore(currentDate, 'day')) {
        isOver = true;
      }
      return <Table.Row key={index} className={isOver ? "homework-is-over" : ""}>
              <Table.Cell onClick={() => that.openHomeworkStatistic(entry.id)} className={`max-width cursor-pointer`} title={i18n("get_homework_statistics")}><Icon name="share square" /> {that.getWorkingGroup(entry)}</Table.Cell>
              <Table.Cell className={`max-width`} title={that.getCategorieTranslations(entry.selectedCategories)}>
                {that.getCategorieTranslations(entry.selectedCategories)}
              </Table.Cell>
              <Table.Cell className="dyn-width" title={that.getReadableDate(entry.enddate)}>
                {that.getReadableDate(entry.enddate)} { isOver && <Icon title={i18n("homework_has_been_completed")} name="check circle outline" /> }
              </Table.Cell>
              <Table.Cell className="dyn-width action-content" onClick={() => that.deleteHomework(entry.id)}>{i18n('delete')}</Table.Cell>
            </Table.Row>
    });
  }

  updateAvailableExam() {
    const that = this;
    API.getExam(function(exam) {
      that.setState({ exam });
    });
  }

  getExamGroup(exam) {
    return exam.group || _.map(exam.selectedStudents, function (student) { return student }).join(', ') || '';
  }

  hasNoEndDate(entry) {
    return entry && entry.noEndDate === true;
  }

  renderAvailableExams() {
    const that = this;
    if (_.isEmpty(this.state.exam)) return null;
    return _.map(this.state.exam, function(entry, index) {
      let isOver = false;
      const givenDate = moment(entry.enddate);
      const currentDate = moment();
      if (givenDate.isBefore(currentDate) && !that.hasNoEndDate(entry)) {
        isOver = true;
      }
      return <Table.Row key={index} className={isOver ? "homework-is-over" : ""}>
              <Table.Cell onClick={() => that.openExamStatistic(entry.id)} className={`max-width cursor-pointer`} title={i18n("get_exam_statistics")}><Icon name="share square" /> {that.getWorkingGroup(entry)}</Table.Cell>
              <Table.Cell className={`max-width`} title={that.getCategorieTranslations(entry.selectedCategories)}>
                {that.getCategorieTranslations(entry.selectedCategories)}
              </Table.Cell>
              <Table.Cell className="dyn-width" title={that.getFullReadableDate(entry)}>
                {that.getFullReadableDate(entry)} { isOver && <Icon title={i18n("exam_has_been_completed")} name="check circle outline" /> }
              </Table.Cell>
              <Table.Cell className="dyn-width action-content" onClick={() => that.deleteExam(entry.id)}>{i18n('delete')}</Table.Cell>
            </Table.Row>
    });
  }

  updateAvailableGroups() {
    const that = this;
    API.getGroups(function(groups) {
      that.setState({ groups });
    });
  }

  renderAvailableGroups() {
    const that = this;
    if (_.isEmpty(this.state.groups)) return null;
    return _.map(this.state.groups, function(entry, index) {
      return <Table.Row key={index}>
              <Table.Cell className="max-width">{entry}</Table.Cell>
              <Table.Cell className="dyn-width action-content" onClick={() => that.deleteGroup(entry)}>{i18n('delete')}</Table.Cell>
            </Table.Row>
    });
  }

  updateAvailableQuestions() {
    const that = this;
    API.getQuestions(function(questions) {
      that.setState({ questions });
    });
  }

  toggleCategoryVisibility(category) {
    this.setState((prevState) => ({
      openCategory: prevState.openCategory === category ? null : category,
    }));
  }

  renderAvailableQuestions() {
    const that = this;
    if (_.isEmpty(this.state.questions)) return null;

    const groupedQuestions = _.groupBy(this.state.questions, 'category');

    return Object.keys(groupedQuestions).map((category) => {
      const questionsInCategory = groupedQuestions[category];
      const questionCount = questionsInCategory && questionsInCategory.length;
      const thisCategoryIsOpen = this.state.openCategory === category;
      const accordionIcon = thisCategoryIsOpen ? "triangle up" : "triangle down";

      return (
        <React.Fragment key={category}>
          <Table.Row>
            <Table.Cell colSpan="5" className="collapsible-item" onClick={() => this.toggleCategoryVisibility(category)}>
              {i18n(category) || category} <span>({questionCount})</span> <Icon name={accordionIcon} />
            </Table.Cell>
          </Table.Row>
          {this.state.openCategory === category && questionsInCategory.map((entry, index) => {
            const { hidden, question, answer1, answer2, answer3, answer4 } = entry;
            return (
              <Table.Row key={index}>
                <Table.Cell>
                  <Checkbox toggle checked={hidden} onChange={(event, data) => that.updateQuestionState(event, data, entry)} />
                </Table.Cell>
                <Table.Cell>{i18n(category) || category}</Table.Cell>
                <Table.Cell className="fbc">
                  {question}
                  {entry.image ? <img className='custom-image-preview' src={entry.image} alt='custom' /> : null}
                </Table.Cell>
                <Table.Cell className='answers'>
                  <div className='correct-answer'>{answer1}</div>
                  <div>{answer2}</div>
                  <div>{answer3}</div>
                  <div>{answer4}</div>
                </Table.Cell>
                <Table.Cell className="dyn-width action-content action-content-question">
                  <div className="single-action" onClick={() => that.deleteQuestion(entry)}>{i18n('delete')}</div>
                  <div className="single-action" onClick={() => that.updateTaskFormular("questions", entry)}>{i18n('edit')}</div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </React.Fragment>
      );
    });
  }

  toggleAnswerLangVisibility(lang) {
    this.setState((prevState) => ({
      openAnswerLanguage: prevState.openAnswerLanguage === lang ? null : lang,
    }));
  }

  renderAvailableAnswers() {
    const that = this;
    if (_.isEmpty(this.state.answers)) return null;
    let count = 0;
    _.each(this.state.answers, function (answer) { count += Object.keys(answer).length; });
    if (count === 0) return null;

    return Object.keys(this.state.answers).map((category) => {
      const answersInLanguageKey = this.state.answers[category];
      const answerCount = Object.keys(answersInLanguageKey).length;
      if (answerCount === 0) return null;

      const thisCategoryIsOpen = this.state.openAnswerLanguage === category;
      const accordionIcon = thisCategoryIsOpen ? "triangle up" : "triangle down";
      return (
        <React.Fragment key={category}>
          <Table.Row>
            <Table.Cell colSpan="5" className="collapsible-item" onClick={() => this.toggleAnswerLangVisibility(category)}>
              {i18n(category) || category} <span>({answerCount})</span> <Icon name={accordionIcon} />
            </Table.Cell>
          </Table.Row>
          {this.state.openAnswerLanguage === category && Object.keys(answersInLanguageKey).map((entry, index) => {
            const id = entry;
            entry = answersInLanguageKey[entry];
            const { customText, question, image } = entry;
            return (
              <Table.Row key={index}>
                <Table.Cell className="id-field-item" title={id}>
                  {id}
                </Table.Cell>
                <Table.Cell className="dyn-width" title={question}>{question}</Table.Cell>
                <Table.Cell className="dyn-width">
                  {customText}
                </Table.Cell>
                <Table.Cell className='answers dyn-width'>
                  {image ? <img className='custom-image-preview float-left' src={image} alt='custom' /> : null}
                </Table.Cell>
                <Table.Cell className="dyn-width action-content action-content-question action-field-item-answers">
                  <div className="single-action" onClick={() => that.deleteAnswer(entry)}>{i18n('delete')}</div>
                  <div className="single-action" onClick={() => that.updateTaskFormular("answer", entry)}>{i18n('edit')}</div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </React.Fragment>
      );
    });
  }

  renderAvailableScenarios() {
    const that = this;
    if (_.isEmpty(this.state.scenarios)) return null;
    let count = Object.keys(this.state.scenarios).length;
    if (count === 0) return null;

    return _.map(this.state.scenarios, function(scenario, id) {
      const scenarioQuestions = scenario.questions && scenario.questions.length;
      const { title, description } = scenario;

      return <Table.Row key={id}>
              <Table.Cell className="max-width">{title}</Table.Cell>
              <Table.Cell className="max-width">{description}</Table.Cell>
              <Table.Cell className="dyn-width action-content action-content-question action-field-item-answers">{scenarioQuestions}</Table.Cell>
              <Table.Cell className="dyn-width action-content action-content-question action-field-item-answers">
                <div className="single-action" onClick={() => that.deleteScenario(id)}>{i18n('delete')}</div>
                <div className="single-action" onClick={() => that.updateTaskFormular("scenario", scenario)}>{i18n('edit')}</div>
              </Table.Cell>
            </Table.Row>
    });
  }

  renderAvailableDocuments() {
    if (_.isEmpty(this.state.documents)) return null;
    let count = Object.keys(this.state.documents).length;
    if (count === 0) return null;

    const that = this;
    return _.map(this.state.documents, function(document, id) {
      // = document.categories;
      //const documentQuestions = document.questions;
      const name = document.name || i18n("document");
      return <Table.Row key={id}>
              <Table.Cell className="max-width">{name}</Table.Cell>
              <Table.Cell className="dyn-width action-content action-content-question action-field-item-answers">
                <div className="single-action" onClick={() => that.deleteDocument(id)}>{i18n('delete')}</div>
                <div className="single-action" onClick={() => that.updateTaskFormular("document", document)}>{i18n('edit')}</div>
              </Table.Cell>
            </Table.Row>
    });
  }

  updateTaskFormular(id, entry) {
    let formular = null;
    switch (id) {
      case 'student': 
        formular = StudentsFormular;
        break;
      case 'questions': 
        formular = QuestionsFormular;
        break;
      case 'answer': 
        formular = AnswerFormular;
        break;
      case 'scenario': 
        formular = ScenarioFormular;
        break;
      case 'document': 
        formular = DocumentsFormular;
        break;
      default:
        formular = null;
    }
    this.setState({ 
      openModal: true,
      formular,
      formularData: entry,
    });
  }

  createNewTask(id) {
    let formular = null;
    let formularActionLabel = null;
    switch (id) {
      case 'groups': 
        formular = GroupsFormular;
        formularActionLabel = i18n("add_student_group");
        break;
      case 'students': 
        formular = StudentsFormular;
        formularActionLabel = i18n("create_flightstudent");
        break;
      case 'student': 
        formular = StudentFormular;
        break;
      case 'categories': 
        formular = CategoriesFormular;
        formularActionLabel = i18n("add_category");
        break;
      case 'questions': 
        formular = QuestionsFormular;
        formularActionLabel = i18n("add_question");
        break;
      case 'homework': 
        formular = HomeworkFormular;
        formularActionLabel = i18n("create_homework");
        break;
      case 'answer': 
        formular = AnswerFormular;
        formularActionLabel = i18n("add_new_answer_explanation");
        break;
      case 'scenario': 
        formular = ScenarioFormular;
        formularActionLabel = i18n("add_new_scenario");
        break;
      case 'homeworkStatistic': 
        formular = HomeworkStatisticFormular;
        break;
      case 'exam': 
        formular = ExamFormular;
        formularActionLabel = i18n("create_exam");
        break;
      case 'examStatistic': 
        formular = ExamStatisticFormular;
        break;
      case 'archive':
        formular = ArchiveFormular;
        break;
      case 'document':
        formular = DocumentsFormular;
        formularActionLabel = i18n("save_document");
        break;
      default:
        formular = null;
    }
    this.setState({ 
      openModal: true,
      formularData: null,
      formularType: id,
      formular,
      formularActionLabel,
    });
  }

  imageToUpload(data) {
    if (data && data.question && data.question.image instanceof FormData) return true;
    if (data && data.answer && data.answer.image instanceof FormData) return true;
    if (data && data.document && data.document.image instanceof FormData) return true;
    return false;
  }

  // used for newStudent/newCategory/newQuestion/newHomework/newExam/newAnswer/newScenario/newDocument
  onSaveData(options) {
    const { data, id } = options.data;

    let endpoint = 'create';
    let availableImageURL = false;

    if (!id || !data) return;

    // update question
    if (this.state.formularData) {
      endpoint = 'update';
      data.id = this.state.formularData.id;

      availableImageURL = this.state.formularData.image;

      if (['student', 'answer', 'question', 'scenario', 'document'].includes(id)) {
        data[id].id = data.id;
      }
    }

    // create new entry
    const api = endpoint + helper.capitalize(id);
    if (this.imageToUpload(data)) {
      const image = (data.question && data.question.image) || (data.answer && data.answer.image) || (data.document && data.document.image);
      // handle document in a different location
      if (id === 'document') {
        API.uploadDocument(image).then((documentURL) => {
          if (data.document) {
            data.document.image = documentURL;
          }
          data.image = documentURL;
          this.saveData(api, data);
        });
      } else {
        // if it's a regular question image, handle it here
        API.imageUpload(image).then((imageURL) => {
          ['question', 'answer'].forEach(key => {
            if (data[key]) {
              data[key].image = imageURL;
            }
          });
          data.image = imageURL[0];
          this.saveData(api, data);
        });
      }
    } else {
      if (availableImageURL) {
        data[id].image = availableImageURL;
      }
      this.saveData(api, data);
    }
  }

  saveData(endpoint, data, options) {
    options = options || {};
    const that = this;
    API[endpoint](data).then((response) => {
      if (options.updateResponseSilently) {
        if (response.question) {
          const id = response.question && response.question.id;
          const questions = that.state.questions;
          questions[id] = response.question;
          that.setState({ questions });
        } else if (response.answer) {
          const lang = response.answer.language;
          const id = response.answer.id;
          const customText = response.answer.customText;
          const answers = that.state.answers;
          answers[lang][id].customText = customText
          that.setState({ answers });
        } else if (response.scenario) {
          const scenario = data.scenario;
          const id = scenario.id;
          const scenarios = that.state.scenarios;
          scenarios[id] = scenario;
          that.setState({ scenarios });
        }
        
      } else {
        that.fetchData();
      }
      that.onCloseModal();
    })
  }

  onCloseModal() {
    this.setState({
      openModal: false,
      formular: null,
      formularOptions: null,
      formularType: null,
      formularActionLabel: null,
    });
  }

  onSearch(options) {
    const { value } = options.target;
    this.setState({ searchTerm: value });
  }

  shouldHideSaveAction() {
    const that = this;
    const hiddenFormulars = [
      StudentFormular,
      HomeworkStatisticFormular,
      ExamStatisticFormular,
      ArchiveFormular,
    ];

    return _.find(hiddenFormulars, function(formular) {
      return formular === that.state.formular;
    });
  }

  closeSearchContainer() {
    this.setState({ searchTerm: "" });
  }

  openArchive(type) {
    this.setState({ formularOptions: { type }});
    this.createNewTask("archive");
  }

  onInnerManagerAction(options) {
    const element = options.type;
    const data = options.data;

    let newTask = "";
    const value = {};

    if (element === "Exam") {
      newTask = "examStatistic";
      value.exam = data;
    } else if (element === "Homework") {
      newTask = "homeworkStatistic";
      value.homework = data;
    }

    this.setState({ formularOptions: value });
    this.createNewTask(newTask);
  }

  prepareCategories(categories) {
    // filter categories for non-public-questions per category for homeworks
    if (this.state.formularType === "homework") {
      // check ob categorie in questions vorkommt + nicht secret ist
      const questions = this.state.questions;
      const availableCategories = categories.filter(category => {
        for (const key in questions) {
          const entry = questions[key];
          if (entry.category === category && entry.hidden === false) {
            return true;
          }
        }
        return false;
      });
      return availableCategories;
    }
    return categories;
  }

  render() {
    if (this.isLoading()) return <Loading />;
    return <div className='manage-flightsschool-container Overview clearfix'>
      <div className='header-information'>
        <label><b>{this.getATOName()}</b></label>
      </div>
      <div className='manage-flightschool-inner-container'>

        <div className='fbc'>
          <button className='btn-second fbf' onClick={this.props.onBack}>{i18n("back")}</button>
          <div className={`fbl mm-search ${this.state.isActiveSearch ? 'active' : ''}`}>
            <input type="search" value={this.state.searchTerm} placeholder={i18n("search_questions")} onChange={this.onSearch} />
            <Icon name="search" title={i18n("search")} onClick={() => this.setState({ isActiveSearch: true })} />
          </div>
        </div>
        
        <div className='mng-headline'>
          <label>{i18n('welcome_to_the_ato_dashboard')}, {this.getFirstName()}!</label>
          <span>{i18n('here_you_get_your_ato_details')}</span>
        </div>

        <PreviewBoxes
          userinfo={this.props.userinfo}
          students={this.state.students}
          groups={this.state.groups}
          categories={this.state.categories}
          questions={this.state.questions}
          answers={this.state.answers}
          homework={this.state.homework}
          exam={this.state.exam}
          scenarios={this.state.scenarios}
          documents={this.state.documents}
          onBoxClick={id => this.createNewTask(id)}  
        />

        <TableList
          tableClass="custom-table"
          headlineText={i18n('all_current_exams')}
          headerActionText={i18n('create_exam')}
          onHeaderAction={id => this.createNewTask('exam')}
          archive={() => this.openArchive("Exam")}
          archiveText={i18n("all_exams")}
          tableHeaderRow={
            <Table.Row>
              <Table.HeaderCell className="max-width">{i18n('flightstudent')}/{i18n('class')}</Table.HeaderCell>
              <Table.HeaderCell className="max-width">{i18n('kategorien')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width">{i18n('due_to_hours')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content">{i18n('actions')}</Table.HeaderCell>
            </Table.Row>
          }
          tableBody={ this.renderAvailableExams() }
          emptyDataValue={i18n('no_exams_available')}
        />

        <TableList
          tableClass="custom-table"
          headlineText={i18n('all_current_homeworks')}
          headerActionText={i18n('create_homework')}
          onHeaderAction={id => this.createNewTask('homework')}
          archive={() => this.openArchive("Homework")}
          archiveText={i18n("all_homework")}
          tableHeaderRow={
            <Table.Row>
              <Table.HeaderCell className="max-width">{i18n('flightstudent')}/{i18n('class')}</Table.HeaderCell>
              <Table.HeaderCell className="max-width">{i18n('kategorien')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width">{i18n('due_to_hours')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content">{i18n('actions')}</Table.HeaderCell>
            </Table.Row>
          }
          tableBody={ this.renderAvailableHomework() }
          emptyDataValue={i18n('no_homework_available')}
        />

        <TableList
          tableClass="custom-table"
          headlineText={i18n('flightschool_classes')}
          headerActionText={i18n('add_student_group')}
          onHeaderAction={id => this.createNewTask('groups')}
          tableHeaderRow={
            <Table.Row>
              <Table.HeaderCell className="max-width">{i18n('class')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content">{i18n('actions')}</Table.HeaderCell>
            </Table.Row>
          }
          tableBody={ this.renderAvailableGroups() }
          emptyDataValue={i18n('no_groups_available')}
        />

        <TableList
          tableClass="custom-table"
          headlineText={i18n('all_flight_students')}
          headerActionText={i18n('create_flightstudent')}
          onHeaderAction={id => this.createNewTask('students')}
          headerActionIcon="info circle"
          headerActionIconInfo={i18n('add_student_with_license')}
          tableHeaderRow={
            <Table.Row>
              <Table.HeaderCell className="hidden-field-item">{i18n('super_user')} <Icon className='' name="info circle" title={i18n('this_is_a_super_user')} /></Table.HeaderCell>
              <Table.HeaderCell className="max-width">{i18n('flightstudent')}</Table.HeaderCell>
              <Table.HeaderCell className="max-width">{i18n('email')}</Table.HeaderCell>
              <Table.HeaderCell className="max-width">{i18n('description')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width space-right-20">{i18n('class')} <Icon className='question-category-icon-fix' name="sort down" /></Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content">{i18n('actions')}</Table.HeaderCell>
            </Table.Row>
          }
          tableBody={ this.renderAvailableStudents() }
          emptyDataValue={i18n('no_students_available')}
        />

        <TableList
          tableClass="custom-table"
          headlineText={i18n('category_count')}
          headerActionIcon="info circle"
          headerActionIconInfo={i18n('default_categories_will_not_be_shown')}
          headerActionText={i18n('add_category')}
          onHeaderAction={id => this.createNewTask('categories')}
          tableHeaderRow={
            <Table.Row>
              <Table.HeaderCell className="max-width">{i18n('category')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content">{i18n('actions')}</Table.HeaderCell>
            </Table.Row>
          }
          tableBody={ this.renderAvailableCategories() }
          emptyDataValue={i18n('no_categorys_available')}
        />

        <TableList
          tableClass="fixed collapsing fullWidth"
          headlineText={i18n('questions')}
          headerActionText={i18n('add_question')}
          onHeaderAction={id => this.createNewTask('questions')}
          tableHeaderRow={
            <Table.Row>
              <Table.HeaderCell className="hidden-field-item">{i18n('hidden')} <Icon className='' name="info circle" title={i18n('show_this_question_in_ato_only')} /></Table.HeaderCell>
              <Table.HeaderCell>{i18n('category')} <Icon className='question-category-icon-fix' name="sort down" /></Table.HeaderCell>
              <Table.HeaderCell>{i18n('question')}</Table.HeaderCell>
              <Table.HeaderCell>{i18n('answers')} <Icon name="info circle" title={i18n('first_answer_in_green_is_correct')} /></Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content action-content-question">{i18n('actions')}</Table.HeaderCell>
            </Table.Row>
          }
          tableBody={this.renderAvailableQuestions()}
          emptyDataValue={i18n('no_questions_available')}
        />

        <TableList
          tableClass="fixed collapsing fullWidth"
          headlineText={i18n('all_question_explanations')}
          headerActionText={i18n('add_new_answer_explanation')}
          onHeaderAction={id => this.createNewTask('answer')}
          tableHeaderRow={
            <Table.Row>
              <Table.HeaderCell className="id-field-item">{i18n('id_number')} <Icon className='question-category-icon-fix' name="sort down" /></Table.HeaderCell>
              <Table.HeaderCell>{i18n('question')}</Table.HeaderCell>
              <Table.HeaderCell>{i18n('explanation')}</Table.HeaderCell>
              <Table.HeaderCell className="action-field-item-answers">{i18n('image')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content action-content-question action-field-item-answers">{i18n('actions')}</Table.HeaderCell>
            </Table.Row>
          }
          tableBody={this.renderAvailableAnswers()}
          emptyDataValue={i18n('no_answers_available')}
        />

        <TableList
          tableClass="fixed collapsing fullWidth"
          headlineText={i18n('scenarios')}
          headerActionText={i18n('add_short_new_scenario')}
          onHeaderAction={id => this.createNewTask('scenario')}
          tableHeaderRow={
            <Table.Row>
              <Table.HeaderCell className="">{i18n('title_of_scenario')}</Table.HeaderCell>
              <Table.HeaderCell>{i18n('description')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content action-content-question action-field-item-answers">{i18n('questions')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content action-content-question action-field-item-answers">{i18n('actions')}</Table.HeaderCell>
            </Table.Row>
          }
          tableBody={this.renderAvailableScenarios()}
          emptyDataValue={i18n('no_scenario_available')}
        />

        <TableList
          tableClass="custom-table"
          headlineText={i18n('documents')}
          headerActionText={i18n('upload_new_document')}
          onHeaderAction={id => this.createNewTask('document')}
          tableHeaderRow={
            <Table.Row>
              <Table.HeaderCell className="max-width">{i18n('document')}</Table.HeaderCell>
              <Table.HeaderCell className="dyn-width action-content">{i18n('actions')}</Table.HeaderCell>
            </Table.Row>
          }
          tableBody={ this.renderAvailableDocuments() }
          emptyDataValue={i18n('no_documents_available')}
        />

        <CreateItemModal
          open={this.state.openModal}
          hideSaveAction={this.shouldHideSaveAction()}
          onClose={this.onCloseModal}
          onSave={this.onSaveData}
          onCallInnerManagerAction={this.props.onCallInnerManagerAction}
          onInnerManagerAction={this.onInnerManagerAction}
          categories={this.prepareCategories(this.state.categories)}
          groups={this.state.groups}
          students={this.state.students}
          formular={this.state.formular}
          formularData={this.state.formularData}
          formularOptions={this.state.formularOptions}
          questions={this.props.questions}
          answers={this.state.answers}
          formularActionLabel={this.state.formularActionLabel}
        />

        {
          this.state.searchTerm && this.state.searchTerm.length > 2 ?
            <SearchContainer
              searchTerm={this.state.searchTerm}
              questions={this.props.questions}
              onClose={this.closeSearchContainer}
            />
          : null
        }

      </div>
    </div>
  }
}

export default ManageFlightschool;
