import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

import $ from 'jquery';
import i18n from "../utils/i18n";

import './css/Tools.css';

class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tool: null,
    };

    this.isIframeActive = false;

    this.cleanup = this.cleanup.bind(this);
    this.close = this.close.bind(this);
    this.registerTouchEvents = this.registerTouchEvents.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.tool && !prevProps.tool) {
      this.registerTouchEvents();
      $('.trainer-container').addClass("fixed noscroll");
      this.cleanup();
    } else {
      if (!this.props.tool) {
        $('.trainer-container').removeClass("fixed noscroll");
      }
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  registerTouchEvents() {
    this.isIframeActive = false;
    const that = this;

    const iframe = document.getElementById('tool-iframe');

    iframe.addEventListener('touchstart', () => this.isIframeActive = true);

    document.addEventListener('touchmove', (e) => {
      console.log("touchmove")
      if (that.isIframeActive) {
        e.preventDefault();
      }
    }, { passive: false });

    iframe.addEventListener('touchend', () => that.isIframeActive = false);
  }

  handleKeyDown(event) {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  openInTab(tool) {
    const url = `./tools/${tool}.html`;
    window.open(url, '_blank');
  }

  close() {
    $('.trainer-container').removeClass("fixed noscroll");
    this.cleanup();
    this.props.onClose();
  };

  cleanup() {
    this.setState({
      tool: null,
    });
  }
  
  render() {
    const { tool, backgroundColor } = this.props;
    if (!tool) return null;
    return <div className={`assistance-overlay tools-container ${tool ? 'open' : ''}`}>
      <div className={`assistance-overlay-container content-container background-${backgroundColor}`}>
      <div onClick={() => this.openInTab(tool)} className='tools-open-in-new-tab'>
          <div className='btn-primary hidden-phone'><Icon name="external alternate" ></Icon> {i18n("open_in_new_tab")}</div>
          <div className='btn-primary hidden-desktop'><Icon name="external alternate" ></Icon></div>
        </div>
        <div onClick={this.close} className='tools-close-btn'>
          <div className='btn-primary hidden-phone'><Icon name="close" ></Icon> {i18n("close")}</div>
          <div className='btn-primary hidden-desktop'><Icon name="close" ></Icon></div>
        </div>
        <iframe id="tool-iframe" title='Tools' src={`./tools/${tool}.html`}></iframe>
      </div>
    </div>
  }
}

export default Tools;
