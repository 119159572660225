import React, { Component } from 'react';
import _ from 'underscore';
import API from '../../../utils/resources/ato.js';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Icon, Modal } from 'semantic-ui-react';

import i18n from '../../../utils/i18n';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class HomeworkFormular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exam: this.props.formularOptions["exam"],
      examDetails: null,
    };

    this.hasNotPassed = this.hasNotPassed.bind(this);
  }

  componentDidMount() {
    const that = this;
    const id = this.state.exam && this.state.exam.id;
    API.getExamStatus({ id }).then((examDetails) => {
      if (examDetails) {
        that.setState({ examDetails });
      }
    });
  }

  hasNotPassed(percent, exam) {
    let status_of_work = exam.status_of_work;
    if (status_of_work) {
      status_of_work = status_of_work === "not_passed";
    } else if (exam.globalResult) {
      status_of_work = exam.globalResult === "Not Passed"; // used as fallback key in this case
    } else {
      status_of_work = percent < 75;
    }
    return status_of_work;
  }

  getPathColor(percent, exam) {
    if (this.hasNotPassed(percent, exam)) return "#d9534f"; // red
    return "#5cb85c"; // green
  }

  getBackgroundColorClassForExams(percent, exam) {
    if (this.hasNotPassed(percent, exam)) return "bg-red"; // red
    return "bg-green"; // green
  }

  getPercentValueOfExam(eaxm) {
    const categories = eaxm.dd.content[5].table.body;
    let categoriesLenght = categories.length;
    let count = 0;
    _.each(categories, function(category) {
      let value = category[2];
      if (_.isObject(value)) { value = value.text; }
      value = value.split("%")[0];
      if (value !== "NaN") {
        count += parseFloat(value);
      } else {
        categoriesLenght--;
      }
    });
    return parseInt(count / categoriesLenght);
  }

  downloadExam(exam) {
    if (!exam) return;
    pdfMake.createPdf(exam.dd).download("ppltrainer-exam-"+ exam.date +".pdf");
  }

  render() {
    const that = this;
    return <Modal.Content scrolling className="mm-content-container">
      <Modal.Header>{i18n("exam_statistics")}</Modal.Header>
      <div className='mm-form-container create-homework homework-details-container'>

      <div className='headline'><span className='headline-description'>{i18n("exam_statistics_description")}</span></div>

      { _.isEmpty(this.state.examDetails) && <div>{i18n("no_exams_available")}</div> }
      {
        _.map(this.state.examDetails, function(exam, id) {
          if (!_.isObject(exam.dd)) {
            exam.dd =  JSON.parse(exam.dd);
          }

          const { date, firstname, lastname, description } = exam;
          
          let displayName = `${firstname} ${lastname}`;
          if (description) {
            displayName += ` (${description})`;
          }

          const value = that.getPercentValueOfExam(exam);
          return (
            <div className={`exam-container ${that.getBackgroundColorClassForExams(value, exam)}`} key={id}>
              <div className='fbf'>
              <CircularProgressbarWithChildren 
                className='circle-exams'
                value={value}
                text={value}
                strokeWidth="15"
                styles={buildStyles({ textColor: that.getPathColor(value, exam), pathColor: that.getPathColor(value, exam), trailColor: "#e4e4e4" })}
                onClick={() => that.downloadExam(exam)}
              ></CircularProgressbarWithChildren>
              </div>
              <div className='fbl'>
                <div>{displayName}, <b>{date}</b></div>
                <div className='exam-container-actions'>
                  <div className="exam-action-item" onClick={() => that.downloadExam(exam) }><Icon disabled name='download' /> {i18n("download")}</div>
                </div>
              </div>
            </div>
          )
        })
      }
     
      </div>  
    </Modal.Content>
  }
}

export default HomeworkFormular;
