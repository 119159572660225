import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import { Dropdown } from 'semantic-ui-react'
import moment from 'moment';

import i18n from "../utils/i18n";
import _ from 'underscore';

class Logout extends Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
    this.tools = {
      "e6-b-front": "E6-B Flight Computer",
      "e6-b-wind": `E6-B Flight Computer (${i18n("wind")})`
    };
    this.languages = {
      "de": "Deutsch",
      "en": "English",
      "cs": "Česky",
      "nl": "Dutch",
      "es": "Español",
      "fi": "Finnska",
      "fr": "Français",
      "is": "Íslenskur",
      "ro": "Română",
      "sk": "Slovenčina",
      "sl": "Slovenščina",
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.userinfo!==this.props.userinfo){
      this.setState({ userinfo: this.props.userinfo });
    }
  }

  async logout() {
    this.props.auth.logout('/')
    .then(function (out){
      console.log("session is deleted");
    })
    .catch(function (error) {
      window.location.href = "/";
    })
  }

  setLanguage(language) {
    i18n.setLanguage(language);
    window.location.reload();
  }

  getActiveClass(lang) {
    if (lang === document.documentElement.lang) return "active";
    return "";
  }

  render() {
    if (!this.props.userinfo) return null;
    const that = this;
    const { email, expirationDate } = this.props.userinfo.idToken.claims;
    
    const diffInDays = moment(expirationDate).diff(moment(new Date()), 'days') + 1;
    let remaining_string = "days_remaining";
    if (diffInDays === 1) remaining_string = "day_remaining";
    const expiration = i18n("days_remaining_prev") + diffInDays + " " + i18n(remaining_string);
    return (
      <Dropdown className="menu-container" direction="left" icon="bars" text={``}>
        <Dropdown.Menu>
          <Dropdown.Item text={email} disabled />
          <Dropdown.Divider />
          <Dropdown.Item text={expiration} disabled />
          <Dropdown.Divider />
          {
            this.props.isATO ? <>
              <Dropdown.Item text={i18n("manage_my_flightschool")} onClick={this.props.onManageMyFlightschool} />
              <Dropdown.Divider />
            </>
            : null
          }
          {
            this.props.isSuperAdmin ? <>
              <Dropdown.Item text={i18n("manage_all_atos")} onClick={this.props.onCreateNewATO} />
              <Dropdown.Divider />
              <Dropdown.Item text={i18n("manage_answers")} onClick={this.props.manageAnswers} />
              <Dropdown.Divider />
            </>
            : null
          }
          {
            <Dropdown text={i18n("helping_tools")} pointing='left' direction="left" className='link item has-children-menu hidden-tools-mobile'>
              <Dropdown.Menu>
              { _.map(this.tools, function(value, key) {
                    return <Dropdown.Item key={key} disabled={false} onClick={() => that.props.onSelectTool(key)}>{value}</Dropdown.Item>;
                })
              }
              </Dropdown.Menu>
            </Dropdown>
          }
          {
            this.props.isATO ? <>
              <Dropdown.Divider className='hidden-tools-mobile' />
              <Dropdown text={i18n("change_language")} pointing='left' direction="left" className='link item has-children-menu'>
                <Dropdown.Menu>
                { _.map(this.languages, function(value, key) {
                      return <Dropdown.Item key={key} className={that.getActiveClass(key)} onClick={() => that.setLanguage(key)}>{value}</Dropdown.Item>;
                  })
                }
                </Dropdown.Menu>
              </Dropdown>
              </>
            : null
          }
          
          <Dropdown.Divider />
          <Dropdown.Item text={i18n("logout")} onClick={this.logout} />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
};

// withAuth() makes Okta "Auth" object available as "this.props.auth"
Logout = withAuth(Logout);

export default Logout;