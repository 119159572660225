import axios from 'axios';

  class API {

    static DEFAULT_USER_URL = process.env.DEFAULT_USER_URL

    static async getFlightschoolForPurchase(data) {
      const endpoint = API.DEFAULT_USER_URL + "/atos/ref";
      try {
        const response = await axios.post(endpoint, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

  }
  
  export default API;