import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import $ from 'jquery';

import i18n from '../utils/i18n';

import Skeleton from './Skeleton';
import AIFeedback from './AIFeedback';
import Attachements from './Attachements';
import API from '../utils/resources/api.js';

import './css/Assistant.css';

class Assistant extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isPending: true,
      content: false,
      image: null,
      contentIsApproved: false,
      showFeedbackDialog: false,
      showAIDisclaimer: true,
      underConstruction: false,
      documents: null,
    };

    this.prevPageHadQuestionContent = false;

    this.close = this.close.bind(this);
    this.cleanup = this.cleanup.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onFeedbackDialogOpen = this.onFeedbackDialogOpen.bind(this);
    this.onFeedbackDialogClose = this.onFeedbackDialogClose.bind(this);
    this.renderFlightschoolAnswer = this.renderFlightschoolAnswer.bind(this);
    this.renderNotPracticableInformation = this.renderNotPracticableInformation.bind(this);
    this.prevPageHadQuestionContentCheck = this.prevPageHadQuestionContentCheck.bind(this);
    this.shouldGetAnswer = this.shouldGetAnswer.bind(this);
  }


  async getAnswer() {
    const { key, question, answers, correctAnswer } = this.props.assistant.question;
    const id = this.props.assistant.questionID;
    const answer = answers[correctAnswer];
    if (key) {
      try {
        const response = await API.getAnswerAssistance({id, question, answer});
        const showAIDisclaimer = response.id === "builtin" ? false : true;
        this.setState({ 
          content: response.content,
          image: response.image,
          question,
          isPending: false,
          showAIDisclaimer: showAIDisclaimer,
        });
        
      } catch (error) {
        console.error(error);
        this.setState({ 
          isPending: false,
          content: "",
          image: null,
          showAIDisclaimer: true,
        });
      }
    }
  }

  renderFlightschoolAnswer() {
    const { question } = this.props.assistant.question;
    const content = this.props.assistant.answer.customText;
    const image = this.props.assistant.answer.image;
    this.setState({
      question,
      content,
      image,
      isPending: false,
      showAIDisclaimer: false,
    });
  }

  shouldGetAnswer() {
    const { img } = this.props.assistant.question;
    if (img) return false;
    return true;
  }

  renderNotPracticableInformation() {
    this.setState({
      content: i18n('under_construction'),
      underConstruction: true,
      isPending: false,
      showAIDisclaimer: false,
    });
  }

  prevPageHadQuestionContentCheck() {
    return document.body.classList.contains("question-content");
  }

  componentDidUpdate(prevProps) {
    if (this.props.assistant && !prevProps.assistant) {
      if (this.prevPageHadQuestionContentCheck()) {
        this.prevPageHadQuestionContent = true;
        document.body.classList.remove("question-content");
      }
      $('.trainer-container').addClass("fixed");
      this.cleanup();

      if (this.props.assistant.answer) {
        // Show answer from flightschool
        this.renderFlightschoolAnswer();

      } else {
        if (this.shouldGetAnswer()) {
          // Get buildin answer or AI answer
          this.getAnswer();
        } else {
          this.renderNotPracticableInformation();
        }
      }
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  cleanup() {
    this.setState({
      content: false,
      image: null,
      isPending: true,
      showFeedbackHint: false,
      showFeedbackDialog: false,
      showAIDisclaimer: true,
      rating: undefined,
      question: null,
      underConstruction: false,
    });
  }

  close() {
    $('.trainer-container').removeClass("fixed");
    if (this.prevPageHadQuestionContent) { 
      document.body.classList.add("question-content");
    }
    this.cleanup();
    this.props.onClose();
  };

  async sendFeedback(options) {
    this.setState({ 
      showFeedbackHint: true,
      showFeedbackDialog: false,
    });

    const { rating, userText } = options;

    const { key, category } = this.props.assistant.question;
    const { content } = this.state;
    const data = { category, key, content, rating, userText: userText || '' };

    await API.feedbackAIAssistance({ data });
  }

  onFeedbackDialogOpen() {
    this.setState({ showFeedbackDialog: true });
  }

  onFeedbackDialogClose() {
    this.setState({ showFeedbackDialog: false });
  }

  render() {
    const { className, assistant } = this.props;
    const { showFeedbackDialog } = this.state;
    return (
      <div className={className}>
        <div className={`assistance-overlay ${assistant ? 'open' : ''}`}>
          <div className="assistance-overlay-container content-container">
            <div onClick={this.close} className='assistance-close-btn'><Icon name="close" /></div>
            { this.state.isPending && <Skeleton /> }
            {
              this.state.content && <>
                <div className="ai-content">

                  <div className='ai-content-question'>{this.state.question}</div>

                  <div className='ai-content-content'>

                    { this.state.underConstruction && <Icon name="write" className='construction-icon-assistant' /> }
                    
                    { this.state.content }

                    { this.state.underConstruction && <div>{i18n('under_construction_help')}</div> }

                    { this.state.image &&
                      <div className='assistanc-image'>
                        <img src={this.state.image} alt="content" />
                      </div>
                    }

                    <AIFeedback 
                      key={this.props.key}
                      onFeedbackDialogOpen={this.onFeedbackDialogOpen}
                      onFeedbackDialogClose={this.onFeedbackDialogClose}
                      open={showFeedbackDialog}
                      showAIDisclaimer={this.state.showAIDisclaimer}
                      sendFeedback={this.sendFeedback}
                      success={this.state.showFeedbackHint}
                    />

                    { 
                      this.props.assistant && this.props.assistant.documents &&  
                      <Attachements documents={this.props.assistant.documents} /> 
                    }

                  </div>

                </div>
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Assistant;
