import React, { Component } from 'react';
import _ from 'underscore';
import Question from './Question';
import Loading from './Loading';
import Assistant from './Assistant';
import { Line } from 'rc-progress';
import { Icon } from 'semantic-ui-react';

import i18n from "../utils/i18n";
import API from '../utils/resources/api';

class QuestionContent extends Component {
  constructor(props) {
    super(props);

    const state = {
      answerIsTrue: null,
      resolveCorrectAnswer: null,
      remainingQuestions: this.getKeysFromInitQuestions(),
      hasBeenVerified: null,
      questions: this.props.questions,
      lastGivenQuestion: null,
      currentQuestion: null,
      prevQuestions: [],
      countIndex: 0,
      readOnly: false,
    };

    this.maxAttempts = 3;
    this.syncStateAfterGivenAnswerNumber = 3;
    this.syncStateCount = 0;

    this.checkAnswer = this.checkAnswer.bind(this);
    this.showCorrectAnswer = this.showCorrectAnswer.bind(this);
    this.setCorrectAnswer = this.setCorrectAnswer.bind(this);
    this.onSelectAnswer = this.onSelectAnswer.bind(this);
    this.getQuestion = this.getQuestion.bind(this);
    this.getNextQuestion = this.getNextQuestion.bind(this);
    this.skipQuestion = this.skipQuestion.bind(this);
    this.getPercentageOfQuestions = this.getPercentageOfQuestions.bind(this);
    this.updatePrevQuestions = this.updatePrevQuestions.bind(this);
    this.prevQuestion = this.prevQuestion.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
    this.shouldSync = this.shouldSync.bind(this);
    this.getPrevQuestionStatus = this.getPrevQuestionStatus.bind(this);
    this.isNextGivenQuestionAvailable = this.isNextGivenQuestionAvailable.bind(this);
    this.isHelpAssistanceAvailable = this.isHelpAssistanceAvailable.bind(this);
    this.findAnswerForGivenQuestion = this.findAnswerForGivenQuestion.bind(this);
    this.questionAppearsInHardQuestionPool = this.questionAppearsInHardQuestionPool.bind(this);
    this.addQuestionToHardQuestionsPool = this.addQuestionToHardQuestionsPool.bind(this);
    this.resetHardQuestionsForGivenQuestion = this.resetHardQuestionsForGivenQuestion.bind(this);
    this.getQuestionDocuments = this.getQuestionDocuments.bind(this);
    this.help = this.help.bind(this);

    this.state = state;
  }

  componentDidMount() {
    document.body.classList.add("question-content");
    // get default (first) question
    this.getQuestion();
  }

  componentWillUnmount() {
    document.body.classList.remove("question-content");
  }

  getKeysFromInitQuestions() {
    if (this.props.questions && this.props.questions.length) {
      const availableLength = this.props.questions.length;
      const arr = [];
      for (let i=0; i<availableLength;i++) arr.push(i);
      return arr;
    } else {
      return [];
    }
  }

  shuffleAnswers(orig) {
    let a = orig.slice();
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  getQuestion() {
    if (this.state.remainingQuestions && this.state.remainingQuestions.length) {

      // get Prev and Next buttons
      this.updatePrevQuestions();

      let randomQuestion;
      let lengthOfRemainingQuestions;
      let randomQuestionNumber;

      if (this.props.mode === "scenario") {
        // Keep order of questions straigth
        randomQuestionNumber = this.state.questions.length - (this.state.remainingQuestions.length);
        randomQuestion = Object.assign({}, this.state.questions[randomQuestionNumber]);
      } else {
        // Get random order for questions
        lengthOfRemainingQuestions = this.state.remainingQuestions.length;
        randomQuestionNumber = Math.floor(Math.random() * lengthOfRemainingQuestions);
        randomQuestion = Object.assign({}, this.state.questions[this.state.remainingQuestions[randomQuestionNumber]]);
      }

      const correctAnswer = randomQuestion.answers[randomQuestion.correctAnswer];

      // shuffle answers
      let answers = this.shuffleAnswers(randomQuestion.answers);
      let answerIndex = answers.indexOf(correctAnswer);

      const question = {
        question: randomQuestion.question,
        answers: answers,
        category: randomQuestion.category,
        correctAnswer: answerIndex,
        img: randomQuestion.img,
        key: randomQuestion.key,
        originalKey: randomQuestion.originalKey,
        topic: randomQuestion.Topic,
        syllabus: randomQuestion.Syllabus,
      };

      // reset hardQuestionsPool for category
      if (this.state.remainingQuestions.length === 1 && this.props.mode === "training") {
        this.resetHardQuestionsForGivenQuestion(question);
      }

      this.setState({
        question,
        randomQuestionNumber,
        currentQuestion: question,
      });

      // Fixing do not update question
      this.forceUpdate();

    } else {
      if (this.props.mode === "simulation") {
        alert(i18n("allQuestionsDone"));
        this.props.backToSimulationOverview();
      } else {
        alert(i18n("noQuestionsLeft"));
        API.syncSettings();
        this.props.abort({ forceReset: true });
      }
      
    }
  }

  skipQuestion() {
    this.setState({
      answerIsTrue: null,
      answer: null,
      question: null,
      isAnswerGiven: null,
      hasBeenVerified: null,
      resolveCorrectAnswer: null,
      lastGivenQuestion: null,
    }, () => this.getNextQuestion());
  }

  getNextQuestion() {
    this.setState({
      answerIsTrue: null,
      answer: null,
      question: null,
      isAnswerGiven: null,
      hasBeenVerified: null,
      resolveCorrectAnswer: null,
    }, () => this.getQuestion());
  }

  resetHardQuestionsForGivenQuestion(question) {
    const category = question.category;
    const questionId = question.originalKey || question.key;
    const hardQuestionsPool = JSON.parse(localStorage.getItem('hardQuestionsPool')) || {};
    if (!hardQuestionsPool[category]) return;
    delete hardQuestionsPool[category][questionId];
    this.setLocalStorageItem(hardQuestionsPool, 'hardQuestionsPool');
  }

  getLocalStorageItem(category) {
    return JSON.parse(localStorage.getItem(category));
  }

  setLocalStorageItem(data, category) {
    localStorage.setItem(category, JSON.stringify(data));
  
    // sync state every (x) correct answers
    if (this.shouldSync()) {
      API.syncSettings();
    }
  }

  shouldSync() {
    this.syncStateCount++;
    if (this.syncStateCount === this.syncStateAfterGivenAnswerNumber) {
      API.syncSettings();
      this.syncStateCount = 0;
    }
  }

  getSessionExam() {
    return JSON.parse(localStorage.getItem("ppltrainer_exam_session"));
  }

  setSessionExam(object) {
    if (!_.isObject(object)) console.error("Can not set localStorage");
    const data = JSON.stringify(object);
    localStorage.setItem("ppltrainer_exam_session", data);
  }

  showCorrectAnswer() {
    const question = this.state.question;
    let lastGivenQuestion = {
      "correctAnswer": question.correctAnswer,
      "givenAnswer": question.correctAnswer,
      "isCorrect": true,
    };
    this.setState({
      selectedAnswer: question.correctAnswer,
      isAnswerGiven: true,
      answer: null,
      lastGivenQuestion,
      resolveCorrectAnswer: true,
      answerIsTrue: true,
    });
  }

  addQuestionToHardQuestionsPool() {
    const questionId = this.state.question.originalKey || this.state.question.key;
    const category = this.state.question.category;
    const hardQuestionsPool = JSON.parse(localStorage.getItem('hardQuestionsPool')) || {};

    if (!hardQuestionsPool[category]) { hardQuestionsPool[category] = {} }

    hardQuestionsPool[category][questionId] = Math.min((hardQuestionsPool[category][questionId] || 0) + 1, this.maxAttempts);
    this.setLocalStorageItem(hardQuestionsPool, 'hardQuestionsPool');
  }

  questionAppearsInHardQuestionPool() {
    if (this.props.mode !== "training") return false;
    const { category, originalKey, key } = this.state.question;
    let questionKey = originalKey || key;
    const hardQuestionsPool = JSON.parse(localStorage.getItem('hardQuestionsPool')) || {};
    if (hardQuestionsPool[category] && hardQuestionsPool[category][questionKey]) {
      let count = hardQuestionsPool[category][questionKey] - 1;
      if (count === 0) {
        delete hardQuestionsPool[category][questionKey]; 
      } else {
        hardQuestionsPool[category][questionKey] = count;
      }
      this.setLocalStorageItem(hardQuestionsPool, 'hardQuestionsPool');
      return true;
    }
    return false;
  }

  checkAnswer() {
    let answer = false;
    if (this.correctAnswer === this.state.selectedAnswer) answer = true;

    this.setState({
      answer,
      hasBeenVerified: true,
    });

    const isSimulation = this.props.mode === "simulation";
    const isScenario = this.props.mode === "scenario";
    const isTraining = this.props.mode === "training";

    if (!answer && isTraining) {
      // Add wrong answer to a pool of wrong answers
      this.addQuestionToHardQuestionsPool();
    }

    if (answer || isSimulation) {
      // get next question if answer was correct or is simulation
      const that = this;

      const { key, category } = this.state.question;

      if (isScenario) {
        // TODO SCENARIO
        // TODO WHAT? NOTHING?

      } else if (isSimulation) {
        // SIMULATION
        this.setState({ isAnswerGiven: false });
        const examAnalytics = that.getSessionExam();
        const analyticsItem = examAnalytics[category];

        if (!analyticsItem) {
          console.error("Can not get localstorage exam for:", category);
          return;
        }

        const givenAnswer = this.state.question.answers[this.state.selectedAnswer];
        const answerElement = {[key]: {
          'question': this.state.question.question,
          'answer': this.state.question.answers[this.state.question.correctAnswer],
          'givenAnswer': givenAnswer,
          'status': i18n(answer.toString()),
        }};

        if (answer) {
          analyticsItem.right.push(answerElement);
        } else {
          analyticsItem.wrong.push(answerElement);
        }
        examAnalytics[category] = analyticsItem;
        that.setSessionExam(examAnalytics);
      } 

      this.setState((prevState) => {
        
        let remainingQuestions = prevState.remainingQuestions;

        // Check if correct given answer appears in hardQuestionPool - If so, keep it in remaining questions
        const questionAppearsInHardQuestionPool = that.questionAppearsInHardQuestionPool();
        if (!questionAppearsInHardQuestionPool) {
          if (!isScenario && !isSimulation) {
            const localStorageItem = this.getLocalStorageItem(category);
            localStorageItem.push(key);
            this.setLocalStorageItem(localStorageItem, category);
          }
          remainingQuestions = remainingQuestions.filter(function(el, key) {
            if (that.props.mode === "scenario") {
              return el !== prevState.randomQuestionNumber;
            }
            return key !== prevState.randomQuestionNumber;
          });
        }
  
        return {
          answerIsTrue: true,
          remainingQuestions,
        }

      }, () => {
        setTimeout(function() {
          that.getNextQuestion();
        }, this.props.mode === "simulation" ? 250 : 500);
      });

    } else {
      // if answer wos not correct  wait for button click
      this.setState({
        answerIsTrue: false,
      });
      
      if (isScenario) {
        // Reset all given questions and start from the begin
        this.setState({ remainingQuestions: this.getKeysFromInitQuestions() });
      }
    }
  }

  setCorrectAnswer(correctAnswer) {
    this.correctAnswer = correctAnswer;
    return correctAnswer;
  }

  updatePrevQuestions() {
    const { prevQuestions, lastGivenQuestion } = this.state;
    let { currentQuestion } = this.state;
    if (_.isEmpty(currentQuestion)) return;

    if (lastGivenQuestion) {
      currentQuestion.check_correctAnswer = lastGivenQuestion.correctAnswer;
      currentQuestion.check_givenAnswer = lastGivenQuestion.givenAnswer;
      currentQuestion.check_isCorrect = lastGivenQuestion.isCorrect;
  
      prevQuestions.push(currentQuestion);
      this.setState({
        prevQuestions,
      });
    } 
  }

  onSelectAnswer(options) {
    // set question to update prev questions
    const question = this.state.question;
    let lastGivenQuestion = {
      "correctAnswer": question.correctAnswer,
      "givenAnswer": options.value,
      "isCorrect": question.correctAnswer === options.value,
    };

    const selectedAnswer = options.value;
    this.setState({
      selectedAnswer,
      isAnswerGiven: true,
      answer: null,
      lastGivenQuestion,
    }, () => {
      // setting to check answer imediatelly after select an answer
      // if user-setting is set to true (go on with checking answer wiithout press check button)
      if (this.props.mode === "training" && this.props.settings.noConfirmationOnClickingAnswer) {
        this.checkAnswer();
      }
    });
  }

  getPercentageOfQuestions() {
    const questionsLength = this.props.questions.length;
    const remainingQuestions = this.state.remainingQuestions.length;
    const difference = questionsLength - remainingQuestions;
    const result = (difference/questionsLength) * 100;
    return result;
  }

  getQuestionLayoutMode() {
    var mode = this.props.mode;

    // can be overwritten by user-setting
    if ( mode === "training" && this.props.settings && this.props.settings.noConfirmationOnClickingAnswer === false) {
      mode = "simulation";
    }

    return mode;
  }

  prevQuestion() {
    let { countIndex } = this.state;
    const { prevQuestions, currentQuestion } = this.state;
    countIndex+=1;

    const question = prevQuestions[prevQuestions.length - countIndex];

    this.setState({
      countIndex,
      question,
      readOnly: true,
      currentQuestion,
    });
    this.forceUpdate();
  }

  nextQuestion() {
    let { countIndex, readOnly } = this.state;
    const { prevQuestions } = this.state;
    countIndex-=1;

    let question;
    if (countIndex === 0) {
      question = this.state.currentQuestion;
      readOnly = false;
    } else {
      question = prevQuestions[prevQuestions.length - countIndex];
    }

    this.setState({
      countIndex,
      question,
      readOnly,
    });
    this.forceUpdate();
  }

  getPrevQuestionStatus() {
    var val = !_.isEmpty(this.state.prevQuestions) && this.state.prevQuestions.length !== this.state.countIndex;
    return !val;
  }

  isNextGivenQuestionAvailable() {
    var val = !_.isEmpty(this.state.prevQuestions) && this.state.countIndex > 0;
    return val;
  }

  getCurrentQuestionKey(options) {
    if (options && options.prevQuestion) {
      // working with prev / next questions
      return this.state.question.originalKey || this.state.question.key;
    }
    return this.state.currentQuestion.originalKey || this.state.currentQuestion.key;
  }

  findAnswerForGivenQuestion(questionID) {
    const answers = this.props.flightschool_answers;
    if (answers[questionID]) {
      return answers[questionID];
    }
    return null;
  }

  getQuestionDocuments(questionID) {
    const documents = this.props.flightschool_documents;
    const question = this.props.questions[0];
    if (!question) return null;
  
    const questionCategory = (question.category.split && question.category.split("_")[1]) || question.category;
  
    const documentsFound = [];
  
    _.each(documents, (document) => {
      const foundInCategories = _.some(document.categories, (category) => category.category === questionCategory);
      const foundInQuestions = _.some(document.questions, (q) => q.questionID === questionID);
  
      if (foundInCategories || foundInQuestions) {
        documentsFound.push(document);
      }
    });
  
    return documentsFound.length > 0 ? documentsFound : null;
  }

  getCurrentQuestion(options) {
    if (options && options.prevQuestion) {
      // working with prev / next questions
      return this.state.question;
    }
    return this.state.currentQuestion;
  }

  help(options) {
    let answer = null;
    let documents = null;

    let questionID = this.getCurrentQuestionKey(options);
    let question = this.getCurrentQuestion(options);

    if (this.props.flightschool_documents) {
      documents = this.getQuestionDocuments(questionID);
    }
    if (this.props.flightschool_answers) {
      answer = this.findAnswerForGivenQuestion(questionID);
    }
    this.setState({
      assistant: {
        question,
        documents,
        questionID,
        answer,
      },
    });
  }

  isHelpAssistanceAvailable() {
    // TODO calculate if content is available
    const questionKey = this.state.currentQuestion && this.state.currentQuestion.key;
    if (questionKey) return true;
    return false;
  }

  render() {
    if (!this.state.question) return <Loading />
    return (
      // mode -> get from settings
      <div className={`trainer-mode-${this.getQuestionLayoutMode()}`}>

        <Question
          mode={this.props.mode}
          time={this.state.time}
          question={this.state.question.question}
          answers={this.state.question.answers}
          hasBeenVerified={this.state.hasBeenVerified}
          correctAnswer={this.setCorrectAnswer(this.state.question.correctAnswer)}
          image={this.state.question.img}
          id={this.state.question.key}
          onSelectAnswer={this.onSelectAnswer}
          isAnswerCorrect={this.state.answer}
          isAnswerGiven={this.state.isAnswerGiven}
          resolveCorrectAnswer={this.state.resolveCorrectAnswer}
          settings={this.props.settings}
          syllabus={this.state.question.syllabus}
          topic={this.state.question.topic}
          readOnly={this.state.readOnly}
          check_correctAnswer={this.state.question.check_correctAnswer}
          check_givenAnswer={this.state.question.check_givenAnswer}
          check_isCorrect={this.state.question.check_isCorrect}
        />
        {
          this.props.mode === "simulation" ?
          <div className="actions">
            <button className="btn-primary" disabled={!this.state.isAnswerGiven} onClick={this.checkAnswer}>{i18n("next")}</button>
          </div>
          :
          <div className={`actions ${this.isHelpAssistanceAvailable() && "help-assistant"}`}>
            
            {/* prev answers and help button */}
            { this.state.readOnly ?
              this.state.question.check_isCorrect === true ?
                <button className="btn-primary" disabled >{i18n("correct")}!</button> : 
                <>
                <button className="btn-primary no-margin" disabled >{i18n("wrong")}!</button>
                <Icon name="lightbulb outline" className='helper-icon' onClick={() => this.help({ prevQuestion: true })} />
                </>
              : null
            }

            {/* default button */}
            { this.state.answerIsTrue === null && this.state.isAnswerGiven && !this.state.readOnly && !this.state.resolveCorrectAnswer ? <button className="btn-primary"  disabled={!this.state.isAnswerGiven} onClick={this.checkAnswer}>{i18n("check")}</button> : null }

            {/* help button to show correct answer */}
            { this.state.answerIsTrue === null  && !this.state.isAnswerGiven && !this.state.readOnly ? <button className="btn-primary btn-help-question" onClick={this.showCorrectAnswer}><Icon name="student" /> {i18n("show_correct_answer")}</button> : null }
            { this.state.answerIsTrue === true && this.state.resolveCorrectAnswer && !this.state.readOnly &&
              <>
                <button className="btn-primary no-margin" onClick={this.getNextQuestion}>{i18n("show_next_question")}</button>
                { <Icon name="lightbulb outline" className='helper-icon' onClick={this.help} /> }
              </>
            }

            {/* correct answer button */}
            { this.state.answerIsTrue === true && !this.state.resolveCorrectAnswer && !this.state.readOnly ? <button className="btn-primary" disabled >{i18n("correct")}!</button> : null }
            
            {/* wrong answer button */}
            { this.state.answerIsTrue === false && !this.state.readOnly &&
              <>
                <button className="btn-primary no-margin" onClick={this.getNextQuestion}>{i18n("wrong_next_question")}</button>
                { <Icon name="lightbulb outline" className='helper-icon' onClick={this.help} /> }
              </>
            }
          </div>
        }
        
        <div className="progress-bar-header top-actions-container">
          <Line percent={this.getPercentageOfQuestions()} strokeWidth="3" strokeColor={this.props.mode === "simulation" ? "#808080" : "#2DC49F"} />
         
        <small>
          {
            this.props.mode !== "simulation" ?
              <button className="btn-second left" disabled={this.getPrevQuestionStatus()} onClick={this.prevQuestion}>
                <Icon className="smaller-screens" name="arrow alternate circle left outline" />
                <span className="larger-screens"><Icon name="arrow left" />{i18n("prev_question")}</span>
              </button>
            : null
          }

          <span>{i18n("noch_remaining")} <b>{this.state.remainingQuestions.length}</b> {i18n("questions_remaining")}</span>
       
          {
          this.props.mode !== "simulation" &&
            this.isNextGivenQuestionAvailable() ?
              <button className="btn-second right" onClick={this.nextQuestion}>
                <Icon className="smaller-screens" name="arrow alternate circle right outline" />
                <span className="larger-screens">{i18n("next_question")} <Icon name="arrow right" /></span>
              </button>
            : 
            // Skip question button for training only and when no answer is given
            this.props.mode === "training" && !this.state.isAnswerGiven &&
              <button className="btn-second right" onClick={this.skipQuestion} title={i18n("skip_question_and_answer_later_title")}>
                <Icon className="smaller-screens" name="arrow alternate circle right outline" />
                <span className="larger-screens">{i18n("postpone_question")} <Icon name="clock outline" /></span>
              </button>
          }
        </small>
        </div>

        <Assistant
          className="assistent-overlay"
          assistant={this.state.assistant}
          onClose={() => this.setState({ assistant: null })}
        />

      </div>
    );
  }
}

export default QuestionContent;
